var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__main_card_container"
  }, [_c('div', {
    staticClass: "_main_card_header"
  }, [_c('div', {
    staticClass: "__header_inner admin_header_padding"
  }, [_c('h2', [_c('button', {
    on: {
      "click": _vm.dynamicBack
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": require("../../../assets/Arrow - Right.svg"),
      "alt": "img"
    }
  }), _vm._v(" Back")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$route.meta.header))])]), _c('div', {
    staticClass: "side_nav_wrapper"
  }, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/finance/bundle_history' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/finance/bundle_history').catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      }
    }
  }, [_vm._v(" Bundle History ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/finance/wallet_history' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/finance/wallet_history').catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      }
    }
  }, [_vm._v(" Wallet History ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/finance/debtors_list' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/finance/debtors_list').catch(err => {});
      }
    }
  }, [_vm._v(" Debtors List ")])])])])]), _c('div', {
    staticClass: "main_card_body"
  }, [_c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };