import appConfig from '@/app.config';
export default {
  page: {
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    dynamicBack() {
      this.$router.go(-1);
    }
  }
};